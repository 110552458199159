import { AppBar, Box, Button, Link as MuiLink } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import type { RootState } from "@stores/store";
import { useEventListener } from "ahooks";
import { useAtomValue } from "jotai";
import Link from "next/link";
import * as React from "react";
import { useSelector } from "react-redux";
import AvatarMenu from "./avatarMenu";
import Logo from "./logo";
import Menu from "./menu";
import MobileMenu from "./mobileMenu";
import ShoppingCart from "./shoppingCart";
import TopNotification, { topNotificationAtom } from "./topNotification";

const BREAk_WIDTH = 1000;

const Header = () => {
  const menuRef = React.useRef(null);
  const menuWrapRef = React.useRef(null);
  // const [smallHeadWidth, setSmallHeadWidth] = React.useState(0);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  // const [isSmallHead, setSmallHead] = React.useState(false);

  const isTopNotificationOpen = useAtomValue(topNotificationAtom);

  const user = useSelector((store: RootState) => store.app.user);

  useEventListener("resize", () => {
    setPosition({
      left: menuWrapRef.current?.offsetLeft,
      top: isTopNotificationOpen
        ? menuWrapRef.current?.offsetHeight + 40
        : menuWrapRef.current?.offsetHeight,
    });
  });

  React.useEffect(() => {
    setPosition({
      left: menuWrapRef.current?.offsetLeft,
      top: isTopNotificationOpen
        ? menuWrapRef.current?.offsetHeight + 40
        : menuWrapRef.current?.offsetHeight,
    });
  }, []);

  React.useEffect(() => {
    setPosition({
      left: menuWrapRef.current?.offsetLeft,
      top: isTopNotificationOpen
        ? menuWrapRef.current?.offsetHeight + 40
        : menuWrapRef.current?.offsetHeight,
    });
  }, [isTopNotificationOpen]);

  const isNotTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: typeof window === "undefined" ? undefined : window,
  });

  return (
    <AppBar
      id="app-header"
      role="navigation"
      position="fixed"
      color="inherit"
      elevation={isNotTop ? 4 : 0}
      sx={{
        borderBottom: isNotTop ? "0px" : "1px solid",
        borderColor: (theme) => theme.palette.divider,
      }}
    >
      <TopNotification />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          px: 2,
          height: 78,
          // height: isTopNotificationOpen ? 118 : 78,
          maxWidth: (theme) => theme.breakpoints.values.xl,
          mx: "auto",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "block",
            [theme.breakpoints.up(BREAk_WIDTH)]: {
              display: "none",
            },
          })}
        >
          <MobileMenu />
        </Box>
        <Box
          sx={(theme) => ({
            ml: 2,
            [theme.breakpoints.up(BREAk_WIDTH)]: {
              ml: 0,
            },
          })}
        >
          <Link href="/" passHref>
            <MuiLink>
              <Logo />
            </MuiLink>
          </Link>
        </Box>
        <Box
          ref={menuWrapRef}
          sx={(theme) => ({
            mx: 2,
            width: 0,
            flex: 1,
            height: "100%",
            overflow: "auto",
            display: "none",
            visibility: "hidden",
            [theme.breakpoints.up(BREAk_WIDTH)]: {
              display: "flex",
              visibility: "visible",
            },
          })}
        >
          <Menu ref={menuRef} position={position} />
        </Box>
        <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
          {user && (
            <Box
              sx={{
                mr: {
                  xs: 0,
                  sm: 2,
                },
              }}
            >
              <ShoppingCart />
            </Box>
          )}
          {user && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              <AvatarMenu
                username={user.name || user.email}
                menuProps={{ sx: { mt: 1 } }}
              />
            </Box>
          )}
          {!user && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
              >
                <Link href="/login" passHref>
                  <MuiLink underline="hover" variant="subtitle2">
                    Sign In
                  </MuiLink>
                </Link>
              </Box>
              <Box sx={{ ml: 3 }}>
                <Link href="/signup" passHref>
                  <MuiLink underline="none">
                    <Button variant="contained">Sign Up</Button>
                  </MuiLink>
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
