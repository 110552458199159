import CloseRounded from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { atom, useAtom } from "jotai";
import React from "react";

export const topNotificationAtom = atom(false);

export const topNotificationLocalAtom = atom(true);

// export const topNotificationLocalAtom = atomWithStorage(
//   "is-printy6-top-notification-open",
//   true
// );

export default function TopNotification() {
  const [localOpen, setLocalOpen] = useAtom(topNotificationLocalAtom);

  const [open, setOpen] = useAtom(topNotificationAtom);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogClose = () => setOpenDialog(false);

  const handleClose = React.useCallback(() => {
    setLocalOpen(false);
  }, []);

  React.useEffect(() => {
    setOpen(localOpen);
  }, [localOpen]);

  const handleOpenDialog = React.useCallback(() => {
    setOpenDialog(true);
  }, []);

  if (!open) {
    return <></>;
  }

  return (
    <Box
      sx={(theme) => ({
        height: 40,
        background: theme.palette.grey[900],
        color: theme.palette.common.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <Box sx={{ width: 40 }}></Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        🎉&nbsp;&nbsp;&nbsp;
        <Typography
          // href="/page/holiday-notice/"
          variant="body2"
          // color="#ffffff"
          // underline="always"
          onClick={handleOpenDialog}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            textDecorationColor: "#ffffff !important",
          }}
        >
          Printy6 Order Deadline Reminder for Coming Chinese New Year Holiday
        </Typography>
      </Box>
      <Box sx={{ width: 40 }}>
        <IconButton
          onClick={handleClose}
          sx={(theme) => ({
            color: theme.palette.common.white,
            // ml: "auto",
            justifyContent: "flex-end",
          })}
        >
          <CloseRounded />
        </IconButton>
      </Box>
      <NotificationDialog open={openDialog} handleClose={handleDialogClose} />
    </Box>
  );
}

const NotificationDialog = (props: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h4">Chinese New Year Holiday Notice</Typography>

        <Stack spacing={1} sx={{ mt: 2 }}>
          <Typography>Dear Printy6 User,</Typography>
          <Typography>
            The Chinese New Year Holiday is approaching at the end of January.
            We have the following holiday arrangement.
          </Typography>
          {/* <Typography>
            Holiday From 2023.1.16 to 2023.1.28. Back to Work on January 28th.
          </Typography> */}
          <Typography>
            Our vacation starts on <b>January 24th, 2025</b> and ends on{" "}
            <b>February 4th, 2025</b>. We will be back to work on{" "}
            <b>February 5th, 2025</b>.
          </Typography>
          <Typography>
            To ensure we can produce and ship your orders before the holiday,
            please make sure you have your orders paid by{" "}
            <b>January 20st, 2025</b>.
          </Typography>
          <Typography>
            Orders paid after <b>January 20th, 2025</b> will be dispatched
            successively within <b>5 days</b> after the end of the holiday.
          </Typography>
          <Typography>
            During the holiday, if you have any question, please leave messages
          </Typography>
          <Typography>to us via support@printy6.com.</Typography>
          <Typography>
            We apologize for any inconvenience this holiday may cause to you.
          </Typography>
          <Typography>Thank you for your support.</Typography>
          <Typography>Best wishes.</Typography>
          <Typography>Printy6 Support Team</Typography>
          <Typography>January 6th, 2025</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  );
};
